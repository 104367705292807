import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IGetUserInfo } from 'entities/auth';

export interface LoginParams {
    email: string;
    password: string;
    rememberMe?: boolean;
}

export interface LoginResponse {
    token: string;
}

export interface GetUserInfoParams {
    data: IGetUserInfo;
    authToken: string;
}

export abstract class IAuthGateway extends Gateway {
    abstract login(params: LoginParams): GatewayResponse<LoginResponse | null>;

    abstract getUserInfo(params: GetUserInfoParams): GatewayResponse<IGetUserInfo>;
}
