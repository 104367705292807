import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { TDashboard } from '@dm/types';
import { IAdminDashboard, IDailySettlementList } from 'entities/dashboard';
import { ApproveDailySettlementParams, GetAdminDashboardParams, GetDailySettlementParams, GetDashboardPropertyCountParams, GetDashboardPropertyCountResponse, GetDashboardPropertyListParams, GetDashboardPropertyListResponse, GetDashboardTransactionCountParams, GetDashboardTransactionCountResponse, GetDashboardTransactionListParams, GetDashboardTransactionListResponse, IDashboardGateway } from './DashboardBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});
export default class DashboardGateway extends IDashboardGateway {
    async getAdminDashboard(params: GetAdminDashboardParams): GatewayResponse<IAdminDashboard> {
        const response: ApiResponse<IAdminDashboard> = await this.api.get('admin/dashboard/getDashboard', {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getStakeholderDashboard(params: GetAdminDashboardParams): GatewayResponse<TDashboard> {
        const response: ApiResponse<TDashboard> = await this.api.get('admin/dashboard/getStakeholderDashboard', {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getDailySettlement(params: GetDailySettlementParams): GatewayResponse<IDailySettlementList> {
        const { authToken, index } = params;
        const response: ApiResponse<IDailySettlementList> = await this.api.get('admin/package/propertyManagement/getDailyPropertyManagementTransactionSettlement', { index }, getHeaders(authToken));
        return this.process(response);
    }

    async approveDailySettlement(params: ApproveDailySettlementParams): GatewayResponse<string | null> {
        const { authToken, id, status } = params;

        const response: ApiResponse<string> = await this.api.put(`admin/package/propertyManagement/dailyPropertyManagementTransactionSettlement?id=${id}&status=${status}`, { }, getHeaders(authToken));
        return this.process(response);
    }

    async getDashboardPropertyCount(params: GetDashboardPropertyCountParams): GatewayResponse<GetDashboardPropertyCountResponse> {
        const { authToken, ...restOfParams } = params;
        const response: ApiResponse<GetDashboardPropertyCountResponse> = await this.api.get('/admin/dashboard/getDashboardPropertyCount', restOfParams, getHeaders(authToken));
        return this.process(response);
    }

    async getDashboardPropertyList(params: GetDashboardPropertyListParams): GatewayResponse<GetDashboardPropertyListResponse> {
        const { authToken, ...restOfParams } = params;
        const response: ApiResponse<GetDashboardPropertyListResponse> = await this.api.get('/admin/dashboard/getDashboardPropertyList', restOfParams, getHeaders(authToken));
        return this.process(response);
    }

    async getDashboardTransactionCount(params: GetDashboardTransactionCountParams): GatewayResponse<GetDashboardTransactionCountResponse> {
        const { authToken, ...restOfParams } = params;
        const response: ApiResponse<GetDashboardTransactionCountResponse> = await this.api.get('/admin/dashboard/getDashboardTransactionCount', restOfParams, getHeaders(authToken));
        return this.process(response);
    }

    async getDashboardTransactionList(params: GetDashboardTransactionListParams): GatewayResponse<GetDashboardTransactionListResponse> {
        const { authToken, ...restOfParams } = params;
        const response: ApiResponse<GetDashboardTransactionListResponse> = await this.api.get('/admin/dashboard/getDashboardTransactionList', restOfParams, getHeaders(authToken));
        return this.process(response);
    }
}
