import { IGetUserInfo } from 'entities/auth';
import { InitState } from 'redux/slices/auth';

const getStartupAttempting = (state: InitState): boolean => state.actions.startup || false;
const getStartupError = (state: InitState): string => state.error.startup || '';

const getLoginAttempting = (state: InitState): boolean => state.actions.login || false;
const getLoginError = (state: InitState): string => state.error.login || '';

const getAuthToken = (state: InitState): string => state.authToken || '';

const getUserInfo = (state: InitState): IGetUserInfo => state.getUserInfo;

const getIsSuperAdmin = (state: InitState): boolean => state.isSuperAdmin || false;

export default {
    getStartupAttempting,
    getStartupError,

    getLoginAttempting,
    getLoginError,

    getAuthToken,
    getUserInfo,

    getIsSuperAdmin,
};
