import { select, call, put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import DashboardGateway from 'api/Dashboard';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';

export default function* watchGetStakeholderDashboard(api: DashboardGateway): SagaWatcherReturnType {
    yield takeEvery('dashboard/getStackeholderDashboardAttempt', handleGetStakeholderDashboard, api);
}

function* handleGetStakeholderDashboard(api: DashboardGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getStakeholderDashboard], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getStackeholderDashboardFailure('Something went wrong. Please try again.'));
            return;
        }
        yield put(Actions.getStackeholderDashboardSuccess(response.data));
    } else {
        yield put(Actions.getStackeholderDashboardFailure(response.message));
    }
}
