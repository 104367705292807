import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import utc from 'dayjs/plugin/utc';
import ReactDatePicker from 'react-datepicker';
import { Colors } from '@dm/bigfish';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Actions from 'redux/Actions';
import MultiSelect from '../components/MultiSelect';

dayjs.extend(utc);

const TotalPropertyCountFilter = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector((state) => state.dashboard.filters);
    const dashboardPropertyCount = useAppSelector((state) => state.dashboard.dashboardPropertyCount);

    const subscriptionType = [
        {
            label: 'Lite',
            value: 'lite',
        },
        {
            label: 'Plus',
            value: 'plus',
        },
        {
            label: 'Unsubscribe',
            value: 'unsubscribe',
        },
    ];

    useEffect(() => {
        dispatch(Actions.getDashboardPropertyCountAttempt({
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
            propertyManagementSubscriptionType: filters.propertyManagementSubscriptionType.length > 0 ? filters.propertyManagementSubscriptionType.join(',') : undefined,
        }));
        dispatch(Actions.getDashboardPropertyListAttempt({
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
            propertyManagementSubscriptionType: filters.propertyManagementSubscriptionType.length > 0 ? filters.propertyManagementSubscriptionType.join(',') : undefined,
            index: 0,
            name: dashboardPropertyCount[0]?.name || '',
        }));
    }, [filters.endDate, filters.startDate, filters.propertyManagementSubscriptionType]);

    useEffect(() => {
        if (dayjs(filters.endDate).isBefore(filters.startDate)) {
            dispatch(Actions.setFilters({ ...filters, endDate: filters.startDate }));
        }
    }, [filters.endDate, filters.startDate]);

    const handleSelectSubscription = (type: string) => {
        if (filters.propertyManagementSubscriptionType.includes(type)) {
            dispatch(Actions.setFilters({
                ...filters,
                propertyManagementSubscriptionType: filters.propertyManagementSubscriptionType.filter((item) => item !== type),
            }));
        } else {
            dispatch(Actions.setFilters({
                ...filters,
                propertyManagementSubscriptionType: [...filters.propertyManagementSubscriptionType, type],
            }));
        }
    };

    const resetFilter = () => {
        dispatch(Actions.resetFilters());
    };

    return (
        <FilterContainer>
            <Filter>
                <MultiSelect
                    options={subscriptionType}
                    selectedOption={filters.propertyManagementSubscriptionType}
                    setSelectedOption={handleSelectSubscription}
                    placeholder='Subscription Type'
                />
                <div>
                    <StyledDatePicker
                        value={dayjs(filters.startDate).format('MM/YYYY')}
                        selected={filters.startDate}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onChange={(e: Date) => dispatch(Actions.setFilters({ ...filters, startDate: e }))}
                        placeholderText='Start Month'
                        showMonthYearPicker
                        showFullMonthYearPicker
                    />
                </div>
                <div>
                    <StyledDatePicker
                        value={dayjs(filters.endDate).format('MM/YYYY')}
                        selected={filters.endDate}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onChange={(e: Date) => dispatch(Actions.setFilters({ ...filters, endDate: e }))}
                        placeholderText='End Month'
                        showMonthYearPicker
                        showFullMonthYearPicker
                    />
                </div>
            </Filter>
            <ClearFilterButton
                onClick={() => resetFilter()}
            >
                Clear Filter
            </ClearFilterButton>
        </FilterContainer>
    );
};

const FilterContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 20px;
    justify-content: space-between;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
`;
const Filter = styled.div`
    display: flex;
    gap:20px;
`;
const ClearFilterButton = styled.button`
    border: none;
    color: #fff;
    background-color: ${Colors.primary};
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 150px;
    height: 40px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    /* box-sizig: border-box; */
    border: solid 1px #a2a2a2;
    border-radius: 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    > .react-datepicker-wrapper {
        width: 150px;
    }
    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    }
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

export default TotalPropertyCountFilter;
