import React, { useState, useEffect, FC } from 'react';
import { Oval } from 'react-loader-spinner';

import { Button, Colors } from '@dm/bigfish';
import { DailySettlementStatus } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import Translate from 'lib/translate';

import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { useAppSelector } from 'redux/hooks';

interface approveSettlementModalProps {
    settlementId: string;
    settlementDate: string;

    isModalOpen: boolean;
    setIsModalOpen: (state: boolean) => void;

    approveSettlementLoading: boolean;
    approveSettlementError: string;
    approveDailySettlement: (id: string, status: DailySettlementStatus) => void;
}

const approveSettlementModal: FC<approveSettlementModalProps> = ({
    settlementId,
    settlementDate,
    isModalOpen,
    approveSettlementLoading,
    approveSettlementError,
    setIsModalOpen,
    approveDailySettlement,
}) => {
    const isLoading = useAppSelector(state => state.dashboard.actions.approveDailySettlement);

    const cancelClickHandler = () => {
        setIsModalOpen(false);
    };

    const renderModalBody = () => {
        if (approveSettlementLoading) {
            return (
                <div style={{ margin: '10px auto' }}>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </div>
            );
        }

        if (approveSettlementError) {
            return (
                <div
                    style={{ color: 'red', fontWeight: 'bold' }}
                >
                    {approveSettlementError}
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <p style={{ marginBottom: '10px' }}>
                    {`${Translate.t('dailySettlementReport.dailySettlementApproveMessage')}`}
                </p>
                <span style={{ fontWeight: 'bold' }}>
                    {dayjs(settlementDate).format('DD-MM-YYYY')}
                </span>
            </div>
        );
    };

    return (
        <Modal
            width='450px'
            height='fit-content'
            show={isModalOpen}
            closeCursor
            onClickClose={() => setIsModalOpen(false)}
        >
            <div
                style={{
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    fontSize: '24px',
                }}
            >
                {`${Translate.t('dailySettlementReport.dailySettlementModalTitle')}`}
            </div>
            <div
                style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {renderModalBody()}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Button
                    label='Confirm'
                    style={{
                        marginRight: '10px',
                    }}
                    onClick={() => approveDailySettlement(settlementId, DailySettlementStatus.approved)}
                    disabled={approveSettlementLoading}
                    loading={isLoading}
                />
                <Button
                    label='Cancel'
                    onClick={cancelClickHandler}
                    disabled={approveSettlementLoading}
                />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.getDashboardGetIsApproveSettlementModalOpen(state),
    approveSettlementLoading: Selectors.getDashboardGetDailySettlementAttempt(state),
    approveSettlementError: Selectors.getDashboardGetDailySettlementFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsModalOpen: (state: boolean) => dispatch(Actions.setIsApproveSettlementModalOpen(state)),
    approveDailySettlement: (id: string, status: DailySettlementStatus) => dispatch(Actions.approveDailySettlementAttempt({ id, status })),
});

export default connect(mapStateToProps, mapDispatchToProps)(approveSettlementModal);
