import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TDashboard } from '@dm/types';
import { IAdminDashboard, IDailySettlement } from 'entities/dashboard';
import { GetDashboardPropertyCountResponse, GetDashboardPropertyListResponse, GetDashboardTransactionCountResponse, GetDashboardTransactionListResponse } from 'api/DashboardBase';
import { GetDailySettlementActionPayload, ApproveDailySettlementActionPayload, DashboardReduxState, GetDashboardPropertyCountActionPayload, GetDashboardPropertyListActionPayload, GetDashboardTransactionListActionPayload, GetDashboardTransactionCountActionPayload } from './types';

const initialState: DashboardReduxState = {
    actions: {
        getAdminDashboard: false,
        getStakeholderDashboard: false,
        getDailySettlement: false,
        approveDailySettlement: false,
        getDashboardPropertyCount: false,
        getDashboardPropertyList: false,
        getDashboardTransactionCount: false,
        getDashboardTransactionList: false,
    },
    filters: {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
        propertyManagementSubscriptionType: [],
        propertyGroupName: [],
        transactionType: [],
        transactionStatus: [],
        name: '',
        index: 0,
    },
    getAdminDashboard: {
        pendingPaymentApproval: {
            total: 0,
            pendingPaymentData: [{
                owner: '',
                receiptCount: 0,
                amount: 0,
                propertyName: '',
                propertyId: '',
                paymentId: '',
            }],
        },
        transactionPendingCompletion: {
            total: 0,
            transactionPendingCompletionData: [{
                amount: 0,
                description: '',
                name: '',
                propertyName: '',
                propertyId: '',
                transactionId: '',
                transactionType: 0,
                fileurl: [],
            }],
        },
        pendingPropertyValidation: {
            total: 0,
            pendingPropertyValidationData: [{
                propertyId: '',
                property: '',
                owner: '',
                description: '',
            }],
        },
    },
    getStakeholderDashboard: {
        totalProperty: {
            totalCount: 0,
            data: [],
        },
        totalTransaction: {
            totalCount: 0,
            data: [],
        },
        totalSubscription: {
            totalCount: 0,
            data: [],
        },
        totalBalance: {
            value: 0,
            negativeBalance: 0,
        },
        designRenoBalance: 0,
    },
    dailySettlement: [],
    isApproveSettlementModalOpen: false,
    dashboardPropertyCount: [],
    dashboardPropertyList: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    dashboardTransactionCount: [],
    dashboardTransactionList: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    error: {
        getAdminDashboard: '',
        getStakeholderDashboard: '',
        getDailySettlement: '',
        approveDailySettlement: '',
        getDashboardPropertyCount: '',
        getDashboardPropertyList: '',
        getDashboardTransactionCount: '',
        getDashboardTransactionList: '',
    },
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<Partial<DashboardReduxState['filters']>>) => {
            state.filters = {
                ...state.filters,
                ...action.payload,
            };
        },
        resetFilters: (state) => {
            state.filters = initialState.filters;
        },
        getAdminDashboardAttempt: (state) => {
            state.actions.getAdminDashboard = true;
            state.error.getAdminDashboard = '';
        },
        getAdminDashboardSuccess: (state, action: PayloadAction<IAdminDashboard>) => {
            state.actions.getAdminDashboard = false;
            state.getAdminDashboard = action.payload;
        },
        getAdminDashboardFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAdminDashboard = false;
            if (action.payload) {
                state.error.getAdminDashboard = action.payload;
            }
        },
        getStackeholderDashboardAttempt: (state) => {
            state.actions.getStakeholderDashboard = true;
            state.error.getStakeholderDashboard = '';
        },
        getStackeholderDashboardSuccess: (state, action: PayloadAction<TDashboard>) => {
            state.actions.getStakeholderDashboard = false;
            state.getStakeholderDashboard = action.payload;
        },
        getStackeholderDashboardFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getStakeholderDashboard = false;
            if (action.payload) {
                state.error.getStakeholderDashboard = action.payload;
            }
        },
        getDailySettlementAttempt: (state, _action: GetDailySettlementActionPayload) => {
            state.actions.getDailySettlement = true;
            state.error.getDailySettlement = '';
        },
        getDailySettlementSuccess: (state, action: PayloadAction<IDailySettlement[]>) => {
            state.actions.getDailySettlement = false;
            state.dailySettlement = action.payload;
        },
        getDailySettlementFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDailySettlement = false;
            if (action.payload) {
                state.error.getDailySettlement = action.payload;
            }
        },

        approveDailySettlementAttempt: (state, _action: ApproveDailySettlementActionPayload) => {
            state.actions.approveDailySettlement = true;
            state.error.approveDailySettlement = '';
        },
        approveDailySettlementSuccess: (state) => {
            state.actions.approveDailySettlement = false;
        },
        approveDailySettlementFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.approveDailySettlement = false;
            if (action.payload) {
                state.error.approveDailySettlement = action.payload;
            }
        },
        setIsApproveSettlementModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isApproveSettlementModalOpen = action.payload;
        },
        getDashboardPropertyCountAttempt: (state, _action: GetDashboardPropertyCountActionPayload) => {
            state.actions.getDashboardPropertyCount = true;
            state.error.getDashboardPropertyCount = '';
        },
        getDashboardPropertyCountSuccess: (state, action: PayloadAction<GetDashboardPropertyCountResponse>) => {
            state.actions.getDashboardPropertyCount = false;
            state.dashboardPropertyCount = action.payload;
        },
        getDashboardPropertyCountFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDashboardPropertyCount = false;
            if (action.payload) {
                state.error.getDashboardPropertyCount = action.payload;
            }
        },
        getDashboardPropertyListAttempt: (state, _action: GetDashboardPropertyListActionPayload) => {
            state.actions.getDashboardPropertyList = true;
            state.error.getDashboardPropertyList = '';
        },
        getDashboardPropertyListSuccess: (state, action: PayloadAction<GetDashboardPropertyListResponse>) => {
            state.actions.getDashboardPropertyList = false;
            state.dashboardPropertyList = action.payload;
        },
        getDashboardPropertyListFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDashboardPropertyList = false;
            if (action.payload) {
                state.error.getDashboardPropertyList = action.payload;
            }
        },
        getDashboardTransactionCountAttempt: (state, _action: GetDashboardTransactionCountActionPayload) => {
            state.actions.getDashboardTransactionCount = true;
            state.error.getDashboardTransactionCount = '';
        },
        getDashboardTransactionCountSuccess: (state, action: PayloadAction<GetDashboardTransactionCountResponse>) => {
            state.actions.getDashboardTransactionCount = false;
            state.dashboardTransactionCount = action.payload;
        },
        getDashboardTransactionCountFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDashboardTransactionCount = false;
            if (action.payload) {
                state.error.getDashboardTransactionCount = action.payload;
            }
        },
        getDashboardTransactionListAttempt: (state, _action: GetDashboardTransactionListActionPayload) => {
            state.actions.getDashboardTransactionList = true;
            state.error.getDashboardTransactionList = '';
        },
        getDashboardTransactionListSuccess: (state, action: PayloadAction<GetDashboardTransactionListResponse>) => {
            state.actions.getDashboardTransactionList = false;
            state.dashboardTransactionList = action.payload;
        },
        getDashboardTransactionListFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDashboardTransactionList = false;
            if (action.payload) {
                state.error.getDashboardTransactionList = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: dashboardSlice.actions,
    reducers: dashboardSlice.reducer,
};
