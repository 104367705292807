import { Colors } from '@dm/bigfish';
import React, { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Dot, Legend } from 'recharts';
import styled from 'styled-components';

export type TEachProperty = {
    name: string;
    value: number;
}

export type TLineChart = {
    month: string;
    year: string;
    value: number;
    value2?: number;
    property?: TEachProperty[];
}

interface CustomLineChartProps {
    data: TLineChart[];
    loading: boolean;
    onClick?: () => void;
}

// Custom dot component for the line points
const CustomDot = (props: any) => {
    const { cx, cy, value, color } = props;
    return (
        <g>
            {/* Outer circle */}
            <circle cx={cx} cy={cy} r={6} fill='white' stroke={color} strokeWidth={2} />
            {/* Inner circle */}
            <circle cx={cx} cy={cy} r={3} fill={color} />
            {/* Value label */}
            <ValueLabel
                x={cx}
                y={cy - 15}
                textAnchor='middle'
                fill='#666'
            >
                {value}
            </ValueLabel>
        </g>
    );
};

// Custom tooltip
const CustomTooltip = ({ active, payload, data, label }: any) => {
    if (active && payload && payload.length) {
        const renderProperty = (): any => {
            if (payload[0].payload.property.length > 0) {
                return payload[0].payload.property.map((item: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={index}>
                        {item.name}
                        :
                        {' '}
                        {item.value}
                    </p>
                ));
            }
            return null;
        };
        return (
            <TooltipContainer>
                <p style={{ color: 'black' }}>{label}</p>
                <p>
                    All Transactions
                    :
                    {' '}
                    {payload[0].value}
                </p>
                {renderProperty()}
                {/* <p>
                    BPMS transactions
                    :
                    {' '}
                    {payload[0].payload.value2}
                </p> */}
            </TooltipContainer>
        );
    }
    return null;
};

// Custom X-Axis Tick with ellipsis
const CustomXAxisTick = ({ x, y, payload }: any) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor='middle'
                fill='#666'
                style={{ fontSize: '12px' }}
            >
                {payload.value.length > 15
                    ? `${payload.value.substring(0, 15)}...`
                    : payload.value}
            </text>
        </g>
    );
};

// Custom Legend
const CustomLegend = (props: any) => {
    const { payload } = props;

    return (
        <LegendContainer>
            {payload.map((entry: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <LegendItem key={`legend-${index}`}>
                    <LegendDot style={{ backgroundColor: entry.color }} />
                    <LegendText>
                        {entry.value === 'value' ? 'All Transactions' : 'BPMS Transactions'}
                    </LegendText>
                </LegendItem>
            ))}
        </LegendContainer>
    );
};

const CustomLineChart: FC<CustomLineChartProps> = ({
    data,
    loading,
    onClick,
}) => {
    const COLOR = '#F2B47E';

    if (loading) {
        return (
            <ChartContainer>
                <Oval
                    height={200}
                    width={200}
                    color={Colors.secondary}
                    secondaryColor={Colors.primary}
                />
            </ChartContainer>
        );
    }

    return (
        <ChartContainer>
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                    onClick={onClick}
                    style={{ cursor: 'pointer' }}
                    data={data}
                    margin={{
                        top: 30,
                        right: 50,
                        left: 50,
                        // bottom: 30, // Reduced bottom margin since we removed angle
                    }}
                >
                    <CartesianGrid
                        strokeDasharray='3 3'
                        vertical={false}
                        stroke='#E5E7EB'
                    />
                    <XAxis
                        dataKey='month'
                        height={60}
                        interval={0}
                        tick={<CustomXAxisTick />}
                    />
                    {/* <YAxis
                        tick={{ fontSize: 12, fill: '#666' }}
                        axisLine={false}
                        tickLine={false}
                    /> */}
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        content={<CustomLegend />}
                        verticalAlign='bottom'
                        height={36}
                    />
                    <Line
                        type='monotone'
                        dataKey='value'
                        stroke={COLOR}
                        strokeWidth={2}
                        dot={<CustomDot />}
                        activeDot={{ r: 8 }}
                    />
                    {/* {data[0].value2 && (
                        <Line
                            type='monotone'
                            dataKey='value2'
                            stroke='#82ca9d'
                            strokeWidth={2}
                            dot={<CustomDot />}
                            activeDot={{ r: 8 }}
                        />
                    )} */}

                </LineChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
};

CustomLineChart.defaultProps = {
    onClick: () => null,
};

const ChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TotalText = styled.text`
    font-size: 14px;
    fill: #666666;
`;

const TotalValue = styled.tspan`
    font-weight: bold;
`;

const ValueLabel = styled.text`
    font-size: 12px;
    font-weight: 500;
`;

const TooltipContainer = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
`;

// New styled components for the legend
const LegendContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    /* margin-top: 16px; */
`;

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const LegendDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
`;

const LegendText = styled.span`
    font-size: 14px;
    color: #666;
`;

export default CustomLineChart;
