import { TDashboard, DailySettlementStatus, PaginatedViewAllPMTransactionsResponse } from '@dm/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IAdminDashboard, IDailySettlementList } from 'entities/dashboard';

export interface GetAdminDashboardParams {
    authToken: string;
}
export interface GetDailySettlementParams{
    authToken: string;
    index: number;
}

export interface ApproveDailySettlementParams {
    authToken: string;
    id: string;
    status: DailySettlementStatus;
}

export type GetDashboardPropertyCountParams = {
    authToken: string;
    startDate: string;
    endDate: string;
    propertyManagementSubscriptionType?: string;
}

export type GetDashboardPropertyCountResponse = {
    name: string;
    lite: number;
    plus: number;
    unsubscribe: number;
}[];

export type GetDashboardPropertyListParams = GetDashboardPropertyCountParams & {
    index: number;
    name: string;
}
export type GetDashboardPropertyListResponse = {
    index: number;
    maxIndex: number;
    data: {
        propertyId: string;
        unitNo: string;
        propertyManagementSubscriptionType: number;
        createdAt: string;
    }[];
}

export type GetDashboardTransactionCountParams = {
    authToken: string;
    startDate: string;
    endDate: string;
    name?: string;
    transactionType?: string;
    transactionStatus?: string;
}

export type GetDashboardTransactionCountResponse = {
    month: string;
    year: string;
    propertyGroup: {
        name: string;
        value: number;
    }[];
}[];

export type GetDashboardTransactionListParams = GetDashboardTransactionCountParams & {
    index: number;
}

export type GetDashboardTransactionListResponse = PaginatedViewAllPMTransactionsResponse;

export abstract class IDashboardGateway extends Gateway {
    abstract getAdminDashboard(params: GetAdminDashboardParams): GatewayResponse<IAdminDashboard>;

    abstract getStakeholderDashboard(params: GetAdminDashboardParams): GatewayResponse<TDashboard>;

    abstract getDailySettlement(params: GetDailySettlementParams): GatewayResponse<IDailySettlementList>;

    abstract approveDailySettlement(params: ApproveDailySettlementParams): GatewayResponse<string | null>;

    abstract getDashboardPropertyCount(params: GetDashboardPropertyCountParams): GatewayResponse<GetDashboardPropertyCountResponse>;

    abstract getDashboardPropertyList(params: GetDashboardPropertyListParams): GatewayResponse<GetDashboardPropertyListResponse>;

    abstract getDashboardTransactionCount(params: GetDashboardTransactionCountParams): GatewayResponse<GetDashboardTransactionCountResponse>;

    abstract getDashboardTransactionList(params: GetDashboardTransactionListParams): GatewayResponse<GetDashboardTransactionListResponse>;
}
