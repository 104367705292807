import { Colors } from '@dm/bigfish';
import React, { FC, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

type TTotalPropertyCountData = {
    name: string;
    lite: number;
    plus: number;
    unsubscribe: number;
}
type ComponentProps = {
    data: TTotalPropertyCountData[];
}

const CustomXAxisTick = ({ x, y, payload }: any) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor='end'
                transform='rotate(-35)'
                fill='#666'
                style={{
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {payload.value.length > 20
                    ? `${payload.value.substring(0, 20)}...`
                    : payload.value}
            </text>
        </g>
    );
};

const TotalPropertyCountChart: FC<ComponentProps> = ({
    data,
}) => {
    const dispatch = useAppDispatch();

    const filters = useAppSelector(state => state.dashboard.filters);
    const getDashboardPropertyCountAttempt = useAppSelector(state => state.dashboard.actions.getDashboardPropertyCount);
    const getDashboardPropertyCountError = useAppSelector(state => state.dashboard.error.getDashboardPropertyCount);

    useEffect(() => {
        if (data.length) {
            dispatch(Actions.setFilters({ ...filters, name: data[0].name }));
        }
    }, []);

    useEffect(() => {
        dispatch(Actions.getDashboardPropertyListAttempt({
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
            propertyManagementSubscriptionType: filters.propertyManagementSubscriptionType.length > 0 ? filters.propertyManagementSubscriptionType.join(',') : undefined,
            index: 0,
            name: filters.name ? filters.name : '',
        }));
    }, [filters.name]);

    const renderBars = () => {
        return (
            <>
                {
                    (filters.propertyManagementSubscriptionType.length === 0
                    || filters.propertyManagementSubscriptionType.includes('lite')) && (
                        <Bar
                            key='lite'
                            dataKey='lite'
                            stackId='a'
                            fill={Colors.primary}
                        />
                    )
                }

                {
                    (filters.propertyManagementSubscriptionType.length === 0
                    || filters.propertyManagementSubscriptionType.includes('plus')) && (
                        <Bar
                            key='plus'
                            dataKey='plus'
                            stackId='a'
                            fill={Colors.secondary}
                        />
                    )
                }

                {
                    (filters.propertyManagementSubscriptionType.length === 0
                    || filters.propertyManagementSubscriptionType.includes('unsubscribe')) && (
                        <Bar
                            key='unsubscribe'
                            dataKey='unsubscribe'
                            stackId='a'
                            fill={Colors.greyDark}
                            label={{ position: 'top' }}
                        />
                    )
                }
            </>
        );
    };

    if (getDashboardPropertyCountAttempt) {
        return (
            <ResponsiveContainer width='100%' height={600}>
                <ChartContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </ChartContainer>
            </ResponsiveContainer>
        );
    }

    if (getDashboardPropertyCountError) {
        return (
            <ChartContainer>
                <p>{getDashboardPropertyCountError}</p>
            </ChartContainer>
        );
    }

    if (!data.length) {
        return (
            <ChartContainer>
                <p>No data</p>
            </ChartContainer>
        );
    }

    return (
        <ResponsiveContainer width='100%' height={600}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                onClick={(e) => {
                    // e.activeLabel contains the x-axis label (name)
                    if (e.activeLabel) {
                        dispatch(Actions.setFilters({ ...filters, name: e.activeLabel }));
                    }
                }}
                style={{ cursor: 'pointer' }}
            >
                <XAxis
                    dataKey='name'
                    height={100}
                    interval={0}
                    tick={<CustomXAxisTick />}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                {renderBars()}
                {/* <Bar dataKey='lite' stackId='a' fill={Colors.primary} onClick={({ name }) => setSelectedProperty(name || '')} />
                <Bar dataKey='plus' stackId='a' fill={Colors.secondary} onClick={({ name }) => setSelectedProperty(name || '')} />
                <Bar dataKey='unsubscribe' stackId='a' fill={Colors.greyDark} label={{ position: 'top' }} onClick={({ name }) => setSelectedProperty(name || '')} /> */}
            </BarChart>
        </ResponsiveContainer>
    );
};

const ChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default TotalPropertyCountChart;
