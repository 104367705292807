import { Colors } from '@dm/bigfish';
import { PropertyManagementSubscriptionType } from '@dm/types';
import dayjs from 'dayjs';
import NavActions from 'lib/NavActions';
import React, { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

type TProperty = {
    propertyId: string;
    unitNo: string;
    propertyManagementSubscriptionType: number;
    createdAt: string;
}
type SelectedPropertyListProps = {
    data: TProperty[];
};

const SelectedPropertyList: FC<SelectedPropertyListProps> = ({
    data,
}) => {
    const getDashboardPropertyListAttempt = useAppSelector(state => state.dashboard.actions.getDashboardPropertyList);
    const getDashboardPropertyListFailure = useAppSelector(state => state.dashboard.error.getDashboardPropertyList);

    const tableHeader = ['Unit No', 'Subscription Type', 'Date Created'];

    const renderTableHeader = () => tableHeader.map((item) => <th>{item}</th>);

    const renderTableContent = () => {
        if (!data.length) return <tr><td colSpan={3} style={{ textAlign: 'center' }}>No data</td></tr>;

        if (getDashboardPropertyListAttempt) {
            return (
                <tr>
                    <td colSpan={3}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Oval
                                height={200}
                                width={200}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        if (getDashboardPropertyListFailure) {
            return (
                <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                        {getDashboardPropertyListFailure}
                    </td>
                </tr>
            );
        }

        return data.map((item) => {
            return (
                <tr
                    style={{ cursor: 'pointer' }}
                    key={item.propertyId}
                    onClick={() => NavActions.navToPropertyDetails(item.propertyId)}
                >
                    <td>
                        {item.unitNo}
                    </td>
                    <td>
                        {PropertyManagementSubscriptionType[item.propertyManagementSubscriptionType]}
                    </td>
                    <td>
                        {dayjs(item.createdAt).format('DD/MM/YYYY')}
                    </td>
                </tr>
            );
        });
    };
    return (
        <PropertyTable>
            <thead>{renderTableHeader()}</thead>
            <tbody>{renderTableContent()}</tbody>
        </PropertyTable>
    );
};

const PropertyTable = styled.table`
    border: 1px solid #D3D3D3;
    border-collapse: collapse;

    th,td {
        padding: 20px;
        border: 1px solid #D3D3D3;
        text-align: center;
    }
`;

export default SelectedPropertyList;
