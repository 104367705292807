import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Translate, { setUserLanguage } from 'lib/translate';
import NavActions from 'lib/NavActions';

import {
    CompanyLogo,
    TabView,
    LanguageSwitcher,
    ProfileButton,
    TabViewMobile,
} from '@dm/bigfish';

import Icons from 'assets/icons';
import { IGetUserInfo } from 'entities/auth';
import { IAuthUserRole } from '@dm/types';
import media from 'lib/Breakpoints';

interface HeaderProps {
    isAuthenticated: string;
    selectedLanguage: string;
    selectedTabView: string;
    getUserInfo: IGetUserInfo;
    getUserInfoData: () => void;
    setLanguage: (language: string) => void;
    setTabView: (tabView: string) => void;
    logout: () => void;
}

const Header = (props: HeaderProps): JSX.Element => {
    const {
        isAuthenticated,
        selectedLanguage,
        getUserInfo,
        getUserInfoData,
        setLanguage,
        selectedTabView,
        setTabView,
        logout,
    } = props;

    const [onProfileDropdown, setOnProfileDropdown] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getUserInfoData();
    }, []);

    const [profileDropdown] = useState([
        {
            text: 'Log Out',
            onClick: () => { logout(); setOnProfileDropdown(false); },
        },
    ]);

    const [tabViewData] = useState([
        {
            text: 'dashboard',
            onClickTabView: () => {
                setTabView('dashboard');
                NavActions.navToDashboard();
            },
        },
        {
            text: 'property',
            onClickTabView: () => {
                setTabView('property');
                NavActions.navToPropertyReports();
            },
        },
        {
            text: 'owners',
            onClickTabView: () => {
                setTabView('owners');
                NavActions.navToReportsOwner();
            },
        },
        {
            text: 'agents',
            onClickTabView: () => {
                setTabView('agents');
                NavActions.navToReaReport();
            },
        },
        {
            text: 'payments',
            onClickTabView: () => {
                setTabView('payments');
                NavActions.navToPayments();
            },
        },
        {
            text: 'approvals',
            onClickTabView: () => {
                setTabView('approvals');
                NavActions.navToCobrokesReport();
            },
        },
        {
            text: 'transactions',
            onClickTabView: () => {
                setTabView('transactions');
                NavActions.navToTransactionsReport();
            },
        },
        {
            text: 'withdrawals',
            onClickTabView: () => {
                setTabView('withdrawals');
                NavActions.navToWithdrawals();
            },
        },
        {
            text: 'sub-admins',
            onClickTabView: () => {
                setTabView('sub-admins');
                NavActions.navToReportSubAdmins();
            },
        },
    ]);

    const [languageSwitcherData] = useState([
        {
            displayText: 'EN',
            language: 'en',
            onClickSetLang: () => {
                changeLanguage('en');
            },
        },
        {
            displayText: 'BM',
            language: 'ms',
            onClickSetLang: () => {
                changeLanguage('ms');
            },
        },
        {
            displayText: '中文',
            language: 'zh',
            onClickSetLang: () => {
                changeLanguage('zh');
            },
        },
    ]);

    const changeLanguage = (lang: string) => {
        setLanguage(lang);
        setUserLanguage(lang);
        window.location.reload();
    };

    const onClickProfileButton = () => {
        setOnProfileDropdown(!onProfileDropdown);
    };

    const displayText = (selected: string) => {
        const selectedObject = languageSwitcherData.find((data) => data.language === selected);

        if (selectedObject) return selectedObject.displayText;

        return 'EN';
    };

    return (
        <HeaderContainer>
            {/* <LeftHeaderContainer> */}
            <StyledTabViewMobile
                isAuthenthicated={!(isAuthenticated === '')}
                selectedTabView={selectedTabView}
                onOverlayClick={() => setIsMenuOpen(false)}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
                data={
                    (getUserInfo.role === IAuthUserRole.Admin)
                        ? tabViewData : (tabViewData.filter((item) => item.text !== 'sub-admins'))
                }
            />
            <StyledCompanyLogo
                companyName={Translate.t('Ui.UiCompanyName')}
                onClickLogo={() => { NavActions.navToHome(); }}
            />
            {/* </LeftHeaderContainer> */}
            <StyledTabView
                isAuthenthicated={!(isAuthenticated === '')}
                selectedTabView={selectedTabView}
                data={
                    (getUserInfo.role === IAuthUserRole.Admin)
                        ? tabViewData : (tabViewData.filter((item) => item.text !== 'sub-admins'))
                }
            />
            <RightHeaderContainer ref={componentRef}>
                <LanguageSwitcherButton
                    data={languageSwitcherData}
                    languageSelected={displayText(selectedLanguage)}
                />
                <StyledProfileButton
                    title='Admin'
                    icon={Icons.UserLoginIcon}
                    dropdownData={profileDropdown}
                    isAuth={isAuthenticated}
                />
            </RightHeaderContainer>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;
`;

const LeftHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const StyledCompanyLogo = styled(CompanyLogo)`
    margin: 10px;
    width: 150px;

    ${media.sm`
        width: 100px;
    `}

    ${media.sm`
        display: none;
    `}
`;

const StyledTabView = styled(TabView) <{ isAuthenthicated: boolean }>`
    display: ${props => (props.isAuthenthicated ? 'flex' : 'none')};
    margin: 10px 20px;
    align-items: center;

    ${media.lg`
        display: none;
    `}
`;

const StyledTabViewMobile = styled(TabViewMobile) <{ isAuthenthicated: boolean }>`
    display: none;
    margin: 10px 20px;
    z-index: 999;
    ${media.lg`
        display: flex;
    `}
`;

const StyledProfileButton = styled(ProfileButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    margin-right: 24px;
`;

const LanguageSwitcherButton = styled(LanguageSwitcher)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RightHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px;
`;

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: Selectors.getAuthAuthToken(state),
    selectedLanguage: Selectors.getUiSelectedLanguage(state),
    selectedTabView: Selectors.getUiSelectedTabView(state),
    getUserInfo: Selectors.getAuthGetUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserInfoData: () => dispatch(Actions.getUserInfoAttempt()),
    setLanguage: (language: string) => dispatch(Actions.setLanguage(language)),
    setTabView: (tabView: string) => dispatch(Actions.setTabView(tabView)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
