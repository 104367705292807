import { fork } from 'redux-saga/effects';

import DashboardGateway from 'api/Dashboard';
import { RootSagaReturnType } from 'sagas/types';

import watchGetAdminDashboard from './getAdminDashboard';
import watchGetStakeholderDashboard from './getStakeholderDashboard';
import watchGetDailySettlement from './getDailySettlement';
import watchApproveDailySettlement from './approveDailySettlement';
import watchGetDashboardPropertyCount from './getDashboardPropertyCount';
import watchGetDashboardPropertyList from './getDashboardPropertyList';
import watchGetDashboardTransactionCount from './getDashboardTransactionCount';
import watchGetDashboardTransactionList from './getDashboardTransactionList';

export default (api: DashboardGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetAdminDashboard, api);
        yield fork(watchGetStakeholderDashboard, api);
        yield fork(watchGetDailySettlement, api);
        yield fork(watchApproveDailySettlement, api);
        yield fork(watchGetDashboardPropertyCount, api);
        yield fork(watchGetDashboardPropertyList, api);
        yield fork(watchGetDashboardTransactionCount, api);
        yield fork(watchGetDashboardTransactionList, api);
    }

    return {
        rootSaga,
    };
};
