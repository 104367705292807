import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';
import React, { useState } from 'react';
import { BreadCrumb } from '@dm/bigfish';

const TotalSubscriptionCount = (): JSX.Element => {
    const breadCrumb = [
        {
            label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
            onClick: () => { NavActions.navToHome(); },
        },
        {
            label: Translate.t('BreadCrumb.BreadCrumbTotalSubscriptionCount'),
        },
    ];

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <div>
                <h1>total subscription count</h1>
            </div>
        </>
    );
};

export default TotalSubscriptionCount;
