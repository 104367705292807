import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetUserInfo } from 'entities/auth';
import { LoginActionPayload, AuthReduxState } from './types';

const initialState: AuthReduxState = {
    actions: {
        login: false,
        startup: true,
        getUserInfo: false,
    },
    authToken: '',
    getUserInfo: {
        userId: '',
        email: '',
        role: 0,
    },
    isSuperAdmin: false,
    error: {
        login: '',
        startup: '',
        getUserInfo: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.startup = false;
            if (action.payload) state.error.startup = action.payload;
        },

        authLoginAttempt: (state, _action: LoginActionPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },

        getUserInfoAttempt: (state) => {
            state.actions.getUserInfo = true;
            state.error.getUserInfo = '';
        },
        getUserInfoSuccess: (state, action: PayloadAction<IGetUserInfo>) => {
            state.actions.getUserInfo = false;
            state.getUserInfo = action.payload;
        },
        getUserInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getUserInfo = false;
            if (action.payload) {
                state.error.getUserInfo = action.payload;
            }
        },
        setIsSuperAdmin: (state, action: PayloadAction<boolean>) => {
            state.isSuperAdmin = action.payload;
        },
        authLogout: (state) => {
            state.actions.login = false;
            state.authToken = '';
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
