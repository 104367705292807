import { Colors } from '@dm/bigfish';
import React, { FC, useMemo, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Dot, Legend, CurveProps } from 'recharts';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

export type TEachProperty = {
    name: string;
    value: number;
}

type TLineChart = {
    month: string;
    year: string;
    propertyGroup: TEachProperty[];
}

interface CustomLineChartProps {
    data: TLineChart[];
    loading?: boolean;
    onClick?: () => void;
}

// New type for transformed data
type TransformedDataItem = {
    month: string;
    [key: string]: string | number; // Allow dynamic property names with number values
}

// Function to generate random color
const generateRandomColor = () => {
    // Predefined color palette - you can modify these colors
    const colors = [
        '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD',
        '#D4A5A5', '#9B59B6', '#3498DB', '#E67E22', '#1ABC9C',
        '#F1C40F', '#8E44AD', '#2980B9', '#E74C3C', '#16A085',
        '#95A5A6', '#34495E', '#9B59B6', '#2ECC71', '#F39C12',
    ];

    return colors[Math.floor(Math.random() * colors.length)];
};

// Custom dot component for the line points
const CustomDot = (props: any) => {
    const { cx, cy, value, stroke, hoveredLine } = props;

    // Only render if we have valid coordinates and value
    if (!cx || !cy || value === null) return null;

    return (
        <g>
            {/* Value label */}
            <text
                x={cx}
                y={cy - 10} // Position above the dot
                textAnchor='middle'
                fill='#666666'
                fontSize='12px'
                fontWeight='500'
                opacity={hoveredLine ? 1 : 0}
            >
                {value}
            </text>

            {/* Outer circle */}
            <circle
                cx={cx}
                cy={cy}
                r={4} // Slightly smaller radius
                fill='white'
                stroke={stroke}
                strokeWidth={2}
                opacity={hoveredLine ? 1 : 0}
            />

            {/* Inner circle */}
            <circle
                cx={cx}
                cy={cy}
                r={2} // Smaller inner circle
                fill={stroke}
                opacity={hoveredLine ? 1 : 0}
            />
        </g>
    );
};

// Custom tooltip
const CustomTooltip = ({ active, payload, label, hoveredLine }: any) => {
    if (active && payload && payload.length) {
        return (
            <TooltipContainer>
                <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '8px' }}>{label}</p>
                {payload.map((entry: any) => (
                    <TooltipRow
                        key={entry.dataKey}
                        style={{
                            opacity: hoveredLine && hoveredLine !== entry.dataKey ? 0 : 1,
                        }}
                    >
                        <ColorDot style={{ backgroundColor: entry.stroke }} />
                        <span>
                            {entry.dataKey}
                            :
                            {' '}
                            {entry.value}
                        </span>
                    </TooltipRow>
                ))}
            </TooltipContainer>
        );
    }
    return null;
};

// Custom X-Axis Tick with ellipsis
const CustomXAxisTick = ({ x, y, payload }: any) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor='middle'
                fill='#666'
                style={{ fontSize: '12px' }}
            >
                {payload.value.length > 15
                    ? `${payload.value.substring(0, 15)}...`
                    : payload.value}
            </text>
        </g>
    );
};

// Custom Legend
const CustomLegend = ({ payload, hoveredLine, setHoveredLine }: any) => {
    return (
        <LegendContainer>
            {payload.map((entry: any) => (
                <LegendItem
                    key={entry.value}
                    onMouseEnter={() => setHoveredLine(entry.value)}
                    onMouseLeave={() => setHoveredLine(null)}
                    style={{ opacity: hoveredLine && hoveredLine !== entry.value ? 0.5 : 1 }}
                >
                    <LegendDot style={{ backgroundColor: entry.color }} />
                    <LegendText>{entry.value}</LegendText>
                </LegendItem>
            ))}
        </LegendContainer>
    );
};

const TransactionsLineChart: FC<CustomLineChartProps> = ({
    data,
    loading,
    onClick,
}) => {
    const dispatch = useAppDispatch();

    const filters = useAppSelector(state => state.dashboard.filters);
    const getDashboardTransactionCountAttempt = useAppSelector(state => state.dashboard.actions.getDashboardTransactionCount);
    const getDashboardTransactionCountError = useAppSelector(state => state.dashboard.error.getDashboardTransactionCount);

    // State to track which line is being hovered
    const [hoveredLine, setHoveredLine] = useState<string | null>(null);

    // Generate and memoize colors for each property
    const propertyColors = useMemo(() => {
        if (!data.length) return {};
        const properties = data[0].propertyGroup.map(item => item.name);
        return properties.reduce((acc, property) => ({
            ...acc,
            [property]: generateRandomColor(),
        }), {} as Record<string, string>);
    }, [data]);

    const transformedData: TransformedDataItem[] = data.map(item => {
        const newItem: TransformedDataItem = { month: item.month };
        item.propertyGroup.forEach(property => {
            newItem[property.name] = property.value;
        });
        return newItem;
    });

    const propertyNames = data.length > 0 ? data[0].propertyGroup.map(item => item.name) : [];

    const renderLines = () => {
        if (filters.propertyGroupName.length > 0) {
            return filters.propertyGroupName.map((property) => {
                return (
                    <Line
                        connectNulls
                        key={property}
                        type='monotone'
                        dataKey={property}
                        name={property}
                        stroke={propertyColors[property]}
                        strokeWidth={2}
                        dot={<CustomDot hoveredLine={hoveredLine === property} />}
                        activeDot={{ r: 6 }}
                        // Apply opacity based on hover state
                        style={{
                            opacity: hoveredLine && hoveredLine !== property ? 0 : 1,
                            transition: 'opacity 0.2s ease',
                        }}
                        onMouseMove={(e: CurveProps) => {
                            if (e?.name) {
                                setHoveredLine(e.name);
                            }
                        }}
                        onMouseLeave={() => setHoveredLine(null)}
                    />
                );
            });
        }
        return propertyNames.map((property) => {
            return (
                <Line
                    key={property}
                    type='monotone'
                    dataKey={property}
                    name={property}
                    stroke={propertyColors[property]}
                    strokeWidth={2}
                    dot={<CustomDot hoveredLine={hoveredLine === property} />}
                    activeDot={{ r: 6 }}
                    // Apply opacity based on hover state
                    style={{
                        opacity: hoveredLine && hoveredLine !== property ? 0 : 1,
                        transition: 'opacity 0.2s ease',
                    }}
                    onMouseMove={(e: CurveProps) => {
                        if (e?.name) {
                            setHoveredLine(e.name);
                        }
                    }}
                    onMouseLeave={() => setHoveredLine(null)}
                />
            );
        });
    };

    if (getDashboardTransactionCountAttempt) {
        return (
            <ResponsiveContainer width='100%' height={600}>
                <ChartContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </ChartContainer>
            </ResponsiveContainer>
        );
    }

    if (getDashboardTransactionCountError) {
        return (
            <ChartContainer>
                <p>{getDashboardTransactionCountError}</p>
            </ChartContainer>
        );
    }

    if (!data.length) {
        return (
            <ChartContainer>
                <p>No data</p>
            </ChartContainer>
        );
    }

    return (
        <ChartContainer>
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                    onClick={onClick}
                    style={{ cursor: 'pointer' }}
                    data={transformedData}
                    margin={{
                        top: 30,
                        right: 30,
                        left: 30,
                    }}
                >
                    <XAxis
                        dataKey='month'
                        height={60}
                        interval={0}
                        tick={<CustomXAxisTick />}
                    />
                    <YAxis
                        includeHidden
                        width={10}
                    />
                    <Tooltip
                        cursor={{ stroke: '#ccc', strokeWidth: 40 }}
                        offset={20}
                        content={<CustomTooltip hoveredLine={hoveredLine} />}
                        // Only show tooltip for hovered line
                        formatter={(value, name) => {
                            if (!hoveredLine || hoveredLine === name) {
                                return [value, name];
                            }
                            return null; // Hide other lines' tooltips
                        }}
                    />
                    <Legend content={<CustomLegend hoveredLine={hoveredLine} setHoveredLine={setHoveredLine} />} />
                    {renderLines()}
                </LineChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
};

TransactionsLineChart.defaultProps = {
    loading: false,
    onClick: () => null,
};

const ChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TotalText = styled.text`
    font-size: 14px;
    fill: #666666;
`;

const TotalValue = styled.tspan`
    font-weight: bold;
`;

const ValueLabel = styled.text`
    font-size: 12px;
    font-weight: 500;
`;

const TooltipContainer = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
`;

// New styled components for the legend
const LegendContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
    /* margin-top: 16px; */
`;

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const LegendDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
`;

const LegendText = styled.span`
    font-size: 14px;
    color: #666;
`;

const TooltipRow = styled.div`
    display: flex;
    align-items: center;
    margin: 4px 0;
    font-size: 12px;
    color: #666666;
`;

const ColorDot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
`;

export default TransactionsLineChart;
