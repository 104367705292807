import media from 'lib/Breakpoints';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Colors, Fonts } from '@dm/bigfish';
import dayjs from 'dayjs';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { connect } from 'react-redux';
import { TDashboard } from '@dm/types';
import { Oval } from 'react-loader-spinner';
import NavActions from 'lib/NavActions';
import CustomLineChart from './CustomLineChart';
import CustomPieChart from './CustomPieChart';

type DashboardProps = {
    adminDashboard: TDashboard,
    getStakeholderDashboardAttempt: boolean,
    getStakeholderDashboardFailure: string,
    getStakeholderDashboard: () => void,
}
const Dashboard: FC<DashboardProps> = ({
    adminDashboard,
    getStakeholderDashboardAttempt,
    getStakeholderDashboardFailure,
    getStakeholderDashboard,
}) => {
    useEffect(() => {
        getStakeholderDashboard();
    }, []);

    const renderTotalPropertyCount = () => {
        if (getStakeholderDashboardAttempt) {
            return (
                <ChartContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </ChartContainer>
            );
        }

        return (
            <TotalPropertyCount>
                <span>Total property</span>
                {adminDashboard.totalProperty.totalCount}
                <p>{`As of ${dayjs().format('DD/MM/YYYY')}`}</p>
            </TotalPropertyCount>
        );
    };

    const renderTotalSubscriptionCount = () => {
        if (getStakeholderDashboardAttempt) {
            return (
                <ChartContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </ChartContainer>
            );
        }

        return (
            <TotalPropertyCount>
                <span>Total property subscribed</span>
                {adminDashboard.totalSubscription.totalCount}
                <p>{`As of ${dayjs().format('DD/MM/YYYY')}`}</p>
            </TotalPropertyCount>
        );
    };
    const renderTotalBalance = () => {
        if (getStakeholderDashboardAttempt) {
            return (
                <ChartContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </ChartContainer>
            );
        }

        return (
            <TotalBalance balance={adminDashboard.totalBalance.negativeBalance}>
                <span>{`${adminDashboard.totalBalance.negativeBalance > 0 ? '+' : '-'} RM${Math.abs(adminDashboard.totalBalance.negativeBalance).toLocaleString('en-US')} (negative balance)`}</span>
                {`RM${Math.abs(adminDashboard.totalBalance.value).toLocaleString('en-US')}`}
                <p>{`Total as of ${dayjs().format('DD/MM/YYYY')}`}</p>
            </TotalBalance>
        );
    };

    const renderDesignRenoBalance = () => {
        if (getStakeholderDashboardAttempt) {
            return (
                <ChartContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </ChartContainer>
            );
        }

        return (
            <TotalBalance balance={adminDashboard.designRenoBalance}>
                {/* <span>{`${designBalanceLastMonth > 0 ? '+' : '-'} RM${Math.abs(designBalanceLastMonth).toLocaleString('en-US')} last month`}</span> */}
                {`RM${Math.abs(adminDashboard.designRenoBalance).toLocaleString('en-US')}`}
                <p>{`Total as of ${dayjs().format('DD/MM/YYYY')}`}</p>
            </TotalBalance>
        );
    };

    return (
        <DashboardContainer>
            <button type='button' className='div1' onClick={() => NavActions.navToTotalPropertyCount()}>
                <p>Total Property Count</p>
                {renderTotalPropertyCount()}
            </button>
            <button type='button' className='div2' onClick={() => NavActions.navToTotalTransactions()}>
                <p>Total Transactions</p>
                <CustomLineChart
                    onClick={() => NavActions.navToTotalTransactions()}
                    data={adminDashboard.totalTransaction.data}
                    loading={getStakeholderDashboardAttempt}
                />
            </button>
            <button type='button' className='div3' onClick={() => NavActions.navToTotalPropertyCount()}>
                <p>Subsciption Count</p>
                {renderTotalSubscriptionCount()}
            </button>
            <div className='div4'>
                <p>Total Balance</p>
                {renderTotalBalance()}
            </div>
            <div className='div5'>
                <p>Design & Reno Revenue</p>
                {renderDesignRenoBalance()}
            </div>
        </DashboardContainer>
    );
};
const DashboardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    > button {
        all: unset;
        display: flex;
        flex-direction: column;
        padding: 20px;
        min-height: 200px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        p {
            font-size: 14px;
            font-weight: 600;
            color: #F2865E;
            text-transform: uppercase;
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        padding: 20px;
        min-height: 200px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        p {
            font-size: 14px;
            font-weight: 600;
            color: #F2865E;
            text-transform: uppercase;
        }
    }

    .div1 {
        grid-area: 1 / 1 / 2 / 2;
        /* border: 1px solid blue; */
    }
    .div2 {
        grid-area: 1 / 2 / 2 / 4;
        /* border: 1px solid purple; */
    }
    .div3 {
        grid-area: 2 / 1 / 3 / 2;
        /* border: 1px solid green; */
    }
    .div4 {
        grid-area: 2 / 2 / 3 / 3;
        /* border: 1px solid red; */
    }
    .div5 {
        grid-area: 2 / 3 / 3 / 4;
        /* border: 1px solid orange; */
    }

    ${media.lg`
        display: flex;
        flex-direction: column;
        gap:20px;
    `}
`;

const TotalPropertyCount = styled.div`
    font-size: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: black;

    span {
        font-size: 1.5rem;
    }

    > p {
        font-size: 1rem;
        margin-top: 10px;
        color: grey;
        text-transform: none;
    }
`;

const TotalBalance = styled.div<{ balance: number }>`
    font-size: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: black;

    span {
        color: ${(props) => (props.balance > 0 ? Colors.success : Colors.danger)};
        font-size: 1.5rem;
    }

    > p {
        font-size: 1rem;
        margin-top: 10px;
        color: grey;
        text-transform: none;
    }
`;

const ChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const mapStateToProps = (state: RootState) => ({
    adminDashboard: Selectors.getDashboardGetStakeholderDashboard(state),
    getStakeholderDashboardAttempt: Selectors.getDashboardGetStakeholderDashboardAttempt(state),
    getStakeholderDashboardFailure: Selectors.getDashboardGetStakeholderDashboardFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getStakeholderDashboard: () => dispatch(Actions.getStackeholderDashboardAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
