import { BreadCrumb, Paginator } from '@dm/bigfish';
import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MainContainer from 'components/MainContainer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Actions from 'redux/Actions';
import TotalPropertyCountChart from '../components/TotalPropertyCountChart';
import TotalPropertyCountFilter from './TotalPropertyCountFilter';
import SelectedPropertyList from '../components/SelectedPropertyList';

const TotalPropertyCount = (): JSX.Element => {
    const breadCrumb = [
        {
            label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
            onClick: () => { NavActions.navToHome(); },
        },
        {
            label: Translate.t('BreadCrumb.BreadCrumbTotalPropertyCount'),
        },
    ];

    const dispatch = useAppDispatch();

    const filters = useAppSelector(state => state.dashboard.filters);
    const dashboardPropertyCount = useAppSelector(state => state.dashboard.dashboardPropertyCount);
    const dashboardPropertyList = useAppSelector(state => state.dashboard.dashboardPropertyList);

    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);

    useEffect(() => {
        setMaxPages(dashboardPropertyList.maxIndex);
        setPaginationData(Array.from({ length: dashboardPropertyList.maxIndex + 1 }, (_, i) => i + 1));

        return () => {
            dispatch(Actions.resetFilters());
        };
    }, []);

    useEffect(() => {
        dispatch(Actions.setFilters({
            ...filters,
            index: pageIndex,
        }));
    }, [pageIndex]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const nextPaginationNumber = () => {
        if (currentMaxPages < maxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }

        if (pageIndex < maxPages) {
            setPageIndex(pageIndex + 1);
        }
    };

    const prevPaginationNumber = () => {
        if (currentMinPages > 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMaxPages - 20);
        }

        if (pageIndex > 1) {
            setPageIndex(pageIndex - 1);
        }
    };

    const firstPaginationNumber = () => {
        setCurrentMinPages(0);
        setCurrentMaxPages(20);

        setPageIndex(1);
    };

    const lastPaginationNumber = () => {
        setCurrentMinPages(maxPages - 20);
        setCurrentMaxPages(maxPages);

        setPageIndex(maxPages);
    };

    const onClickPaginationNumber = (index: number) => {
        const min = index - 1;
        const max = index + 1;

        if (min < 0) {
            setCurrentMinPages(0);
            setCurrentMaxPages(20);
        } else if (max > maxPages) {
            setCurrentMinPages(maxPages - 20);
            setCurrentMaxPages(maxPages);
        } else {
            setCurrentMinPages(min);
            setCurrentMaxPages(max);
        }

        setPageIndex(index);
    };

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Content>
                    <TotalPropertyCountFilter />
                    <ChartContainer>
                        <TotalPropertyCountChart data={dashboardPropertyCount} />
                    </ChartContainer>
                    <PropertyListContainer>
                        <h1>{filters.name}</h1>
                        <SelectedPropertyList data={dashboardPropertyList.data || []} />
                        <PaginatorContainer>
                            <Paginator
                                currentIndex={pageIndex}
                                data={paginationData}
                                onNext={nextPaginationNumber}
                                onPrev={prevPaginationNumber}
                                onFirst={firstPaginationNumber}
                                onLast={lastPaginationNumber}
                                onClickIndex={onClickPaginationNumber}
                            />
                        </PaginatorContainer>
                    </PropertyListContainer>
                </Content>
            </MainContainer>
        </>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
`;
const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PropertyListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PaginatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default TotalPropertyCount;
