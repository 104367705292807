import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import Dummy from 'containers/Dummy';
import LoginScreen from 'containers/auth/Login';
import HomeScreen from 'containers/home';
import TotalPropertyCount from 'containers/home/statistic/TotalPropertyCount';
import TotalSubscriptionCount from 'containers/home/statistic/TotalSubscriptionCount';
import TotalTransactions from 'containers/home/statistic/TotalTransactions';
import CreateNew from 'containers/owners/CreateNew';
import OwnersReport from 'containers/owners';
import PropertyReport from 'containers/property';
import PropertyDetails from 'containers/property/PropertyDetails';
import EditProperty from 'containers/property/EditProperty';
import PropertyGroupDetails from 'containers/property/PropertyGroupDetails';
import EditPropertyGroup from 'containers/property/EditPropertyGroup';
import PaymentScreen from 'containers/payments';
import OwnerDetailScreen from 'containers/owners/OwnerDetail';
import CreateEventScreen from 'containers/packages/createEvent';
import CobrokesReport from 'containers/rea/CobrokesReport';
import Transaction from 'containers/transaction/Transactions';
import Withdrawal from 'containers/withdrawal/Withdrawal';
import ReaReport from 'containers/rea';
import ReaDetails from 'containers/rea/ReaDetails';
import PackagesLeaseAndSale from 'containers/packages/LeaseSalePackages/LeaseSale';
import LeaseAndSaleViewDocument from 'containers/packages/LeaseSalePackages/LeaseAndSaleViewDocuments';
import PackagesVacantPossesion from 'containers/packages/VacantPossessionPackages/VacantPossession';
import VacantPossesionViewDocument from 'containers/packages/VacantPossessionPackages/VacantPosessionViewDocuments';
import PackagesDefectInspection from 'containers/packages/DefectInspectionPackages/DefectInspection';
import PackagesVacantManagement from 'containers/packages/VacantManagementPackages/VacantManagement';
import DefectInspectionViewDocuments from 'containers/packages/DefectInspectionPackages/DefectInspectionViewDocuments';
import VacantManagementViewDocument from 'containers/packages/VacantManagementPackages/VacantManagementViewDocument';
import PackagesPropertyManagement from 'containers/packages/PropertyManagementPackages/PropertyManagement';
import PropertyManagementViewDocument from 'containers/packages/PropertyManagementPackages/PropertyManagementViewDocument';
import PropertyManagementViewTransaction from 'containers/packages/PropertyManagementPackages/PropertyManagementViewTransaction';
import SubAdminReport from 'containers/subadmins';

import PrivateRoute from './PrivateRoutes';

const PrivateBucket = (): JSX.Element => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route index element={<HomeScreen />} />
            <Route path='/dashboard' element={<HomeScreen />} />
            <Route path='/dashboard/totalPropertyCount' element={<TotalPropertyCount />} />
            <Route path='/dashboard/totalSubscriptionCount' element={<TotalSubscriptionCount />} />
            <Route path='/dashboard/totalTransactions' element={<TotalTransactions />} />
            <Route path='/modules' element={<HomeScreen />} />

            <Route path='/newOwner' element={<CreateNew />} />
            <Route path='/reports/owners' element={<OwnersReport />} />
            <Route path='/reports/owners/ownerDetails/:id' element={<OwnerDetailScreen />} />

            <Route path='/reports/properties/createEvent/:propertyId' element={<CreateEventScreen />} />
            <Route path='/reports/properties' element={<PropertyReport />} />
            <Route path='/propertyDetails/:id' element={<PropertyDetails />} />
            <Route path='/editProperty' element={<EditProperty />} />
            <Route path='/propertyGroupDetails/:id' element={<PropertyGroupDetails />} />
            <Route path='/editPropertyGroup' element={<EditPropertyGroup />} />

            <Route path='/packagesLeaseAndSale' element={<PackagesLeaseAndSale />} />
            <Route path='/leaseAndSaleViewDocument' element={<LeaseAndSaleViewDocument />} />
            <Route path='/packagesVacantPosession' element={<PackagesVacantPossesion />} />
            <Route path='/packagesDefectInspection' element={<PackagesDefectInspection />} />
            <Route path='/vacantPosessionViewDocument' element={<VacantPossesionViewDocument />} />
            <Route path='/defectInspectionViewDocument' element={<DefectInspectionViewDocuments />} />

            <Route path='/packagesVacantManagement' element={<PackagesVacantManagement />} />
            <Route path='/vacantManagementViewDocument' element={<VacantManagementViewDocument />} />
            <Route path='/packagesPropertyManagement' element={<PackagesPropertyManagement />} />
            <Route path='/propertyManagementViewDocument' element={<PropertyManagementViewDocument />} />
            <Route path='/propertyManagementViewTransaction' element={<PropertyManagementViewTransaction />} />

            <Route path='/reports/payments' element={<PaymentScreen />} />
            <Route path='/reports/cobrokes' element={<CobrokesReport />} />
            <Route path='/reports/transactions' element={<Transaction />} />

            <Route path='/reports/rea' element={<ReaReport />} />
            <Route path='/reports/rea/reaDetail/:id' element={<ReaDetails />} />

            <Route path='/reports/sub-admins' element={<SubAdminReport />} />

            <Route path='/listings' element={<Dummy />} />
            <Route path='/notifications' element={<Dummy />} />
            <Route path='/reports' element={<Dummy />} />
            <Route path='/approvals' element={<Dummy />} />
            <Route path='/reports/withdrawals' element={<Withdrawal />} />
        </Route>
    );
};

const NavRoutes = (): JSX.Element => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/login' element={<LoginScreen />} />

                <Route path='*' element={<Navigate replace to='/' />} />
                {PrivateBucket()}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
