import { select, call, put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import DashboardGateway from 'api/Dashboard';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import { GetDailySettlementActionPayload } from 'redux/slices/dashboard/types';

export default function* watchGetDailySettlement(api: DashboardGateway): SagaWatcherReturnType {
    yield takeEvery('dashboard/getDailySettlementAttempt', handleGetDailySettlement, api);
}

function* handleGetDailySettlement(api: DashboardGateway, data: GetDailySettlementActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { index } = data.payload;
    const response = yield* call([api, api.getDailySettlement], {
        authToken,
        index,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.getDailySettlementFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.getDailySettlementSuccess(response.data.data));
    } else {
        yield put(Actions.getDailySettlementFailure(response.message));
    }
}
