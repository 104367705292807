import authSlice from 'redux/slices/auth';
import dashboardSlice from 'redux/slices/dashboard';
import uiSlice from 'redux/slices/ui';
import ownersSlice from 'redux/slices/owners';
import propertySlice from 'redux/slices/property';
import paymentSlice from 'redux/slices/payment';
import packagesSlice from 'redux/slices/packages';
import reaSlice from 'redux/slices/rea';
import subAdminSlice from 'redux/slices/subadmins';

export default {
    ...authSlice.actions,
    ...dashboardSlice.actions,
    ...uiSlice.actions,
    ...ownersSlice.actions,
    ...propertySlice.actions,
    ...paymentSlice.actions,
    ...packagesSlice.actions,
    ...reaSlice.actions,
    ...subAdminSlice.actions,
};
