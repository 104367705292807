import { TDashboard } from '@dm/types';
import { IAdminDashboard, IDailySettlement } from 'entities/dashboard';
import { InitState } from 'redux/slices/dashboard';

const getAdminDashboard = (state: InitState): IAdminDashboard => state.getAdminDashboard;
const getAdminDashboardAttempt = (state: InitState): boolean => state.actions.getAdminDashboard || false;
const getAdminDashboardFailure = (state: InitState): string => state.error.getAdminDashboard || '';

const getStakeholderDashboard = (state: InitState): TDashboard => state.getStakeholderDashboard;
const getStakeholderDashboardAttempt = (state: InitState): boolean => state.actions.getStakeholderDashboard || false;
const getStakeholderDashboardFailure = (state: InitState): string => state.error.getStakeholderDashboard || '';

const getDailySettlement = (state: InitState): IDailySettlement[] => state.dailySettlement;
const getDailySettlementAttempt = (state: InitState): boolean => state.actions.getDailySettlement || false;
const getDailySettlementFailure = (state: InitState): string => state.error.getDailySettlement || '';

const getIsApproveSettlementModalOpen = (state:InitState) : boolean => state.isApproveSettlementModalOpen || false;

export default {
    getAdminDashboard,
    getAdminDashboardAttempt,
    getAdminDashboardFailure,

    getStakeholderDashboard,
    getStakeholderDashboardAttempt,
    getStakeholderDashboardFailure,

    getDailySettlement,
    getDailySettlementAttempt,
    getDailySettlementFailure,

    getIsApproveSettlementModalOpen,
};
