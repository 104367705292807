import { select, call, put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import DashboardGateway from 'api/Dashboard';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import { GetDashboardTransactionCountActionPayload } from 'redux/slices/dashboard/types';
import { toast } from 'react-toastify';
import { PMTransactionTypeEnum, PropertyManagementTransactionStatus } from '@dm/types';

export default function* watchGetDashboardTransactionCount(api: DashboardGateway): SagaWatcherReturnType {
    yield takeEvery('dashboard/getDashboardTransactionCountAttempt', handleGetDashboardTransactionCount, api);
}

function* handleGetDashboardTransactionCount(api: DashboardGateway, data: GetDashboardTransactionCountActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { name, transactionStatus, transactionType, startDate, endDate } = data.payload;

    const status = () => {
        const result: PropertyManagementTransactionStatus[] = [];

        if (!transactionStatus) {
            result.push(PropertyManagementTransactionStatus.Accepted);
            result.push(PropertyManagementTransactionStatus.Completed);
            result.push(PropertyManagementTransactionStatus.Pending);
            result.push(PropertyManagementTransactionStatus.Rejected);
            result.push(PropertyManagementTransactionStatus.Reverted);
            return result.join(',');
        }

        const arrayValue = transactionStatus.split(',');

        if (arrayValue.includes('pending')) {
            result.push(PropertyManagementTransactionStatus.Pending);
        }
        if (arrayValue.includes('accepted')) {
            result.push(PropertyManagementTransactionStatus.Accepted);
        }
        if (arrayValue.includes('rejected')) {
            result.push(PropertyManagementTransactionStatus.Rejected);
        }
        if (arrayValue.includes('completed')) {
            result.push(PropertyManagementTransactionStatus.Completed);
        }
        if (arrayValue.includes('reverted')) {
            result.push(PropertyManagementTransactionStatus.Reverted);
        }

        return result.join(',');
    };

    const type = () => {
        const result: PMTransactionTypeEnum[] = [];

        if (!transactionType) {
            result.push(PMTransactionTypeEnum.rental);
            result.push(PMTransactionTypeEnum.topup);
            result.push(PMTransactionTypeEnum.withdrawal);
            result.push(PMTransactionTypeEnum.transaction);
            return result.join(',');
        }

        const arrayValue = transactionType.split(',');

        if (arrayValue.includes('rental')) {
            result.push(PMTransactionTypeEnum.rental);
        }
        if (arrayValue.includes('topup')) {
            result.push(PMTransactionTypeEnum.topup);
        }
        if (arrayValue.includes('withdrawal')) {
            result.push(PMTransactionTypeEnum.withdrawal);
        }
        if (arrayValue.includes('transaction')) {
            result.push(PMTransactionTypeEnum.transaction);
        }

        return result.join(',');
    };

    const response = yield* call([api, api.getDashboardTransactionCount], {
        authToken,
        startDate,
        endDate,
        name,
        transactionStatus: status(),
        transactionType: type(),
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            toast.error('Something went wrong. Please try again.');
            yield put(Actions.getDashboardTransactionCountFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.getDashboardTransactionCountSuccess(response.data));
    } else {
        yield put(Actions.getDashboardTransactionCountFailure(response.message));
        toast.error(response.message);
    }
}
