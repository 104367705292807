/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Colors } from '@dm/bigfish';
import icons from 'assets/icons';
import React, { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export type TOptions = {
    value: string,
    label: string
}

type ComponentProps = {
    options: TOptions[],
    selectedOption: string[],
    setSelectedOption: (value: string) => void;
    placeholder?: string;
}

const MultiSelect: FC<ComponentProps> = ({
    options,
    selectedOption,
    setSelectedOption,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderSubscriptionFilter = () => {
        return options.map((item) => {
            return (
                <div
                    key={item.value}
                    onClick={() => {
                        setSelectedOption(item.value);
                        setIsOpen(false); // Optional: close dropdown after selection
                    }}
                >
                    <p>
                        {item.label}
                    </p>
                    <div>
                        {
                            selectedOption.includes(item.value)
                            && <Icons src={icons.checkmark} width='30px' height='20px' stroke='black' />
                        }
                    </div>
                </div>
            );
        });
    };

    return (
        <MultipleSelectContainer ref={containerRef}>
            <SelectLabel
                isOptionSelected={selectedOption.length > 0}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption.length > 0 ? selectedOption.join(', ') : placeholder}
            </SelectLabel>
            <ChevronIcon src={icons.ChevronIcon} width='30px' height='20px' isOpen={isOpen} />
            <SelectDropdown isOpen={isOpen}>
                {renderSubscriptionFilter()}
            </SelectDropdown>
        </MultipleSelectContainer>
    );
};

const MultipleSelectContainer = styled.div`
    border: solid 1px #a2a2a2;
    border-radius: 10px;
    position: relative;
    min-width: 200px;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SelectLabel = styled.div<{ isOptionSelected: boolean }>`
    padding: 10px;
    color: ${props => (props.isOptionSelected ? Colors.black : Colors.greyDark)};
    cursor: pointer;
    width: 100%;
`;

const Icons = styled(SVG) <{ width: string, height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
`;
const ChevronIcon = styled(SVG) <{ width: string, height?: string, isOpen?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;
    transform: ${props => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const SelectDropdown = styled.div<{ isOpen: boolean }>`
    position: absolute;
    top: 50px;
    left: 0;
    gap:10px;
    z-index: 20;
    border-radius: 10px;
    border: solid 1px #a2a2a2;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    min-width: 250px;
    background-color: #fff;

    > div {
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
    }

    > div:hover {
        background-color: ${Colors.primary};
    }
`;

MultiSelect.defaultProps = {
    placeholder: '',
};

export default MultiSelect;
