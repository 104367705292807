import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import utc from 'dayjs/plugin/utc';
import ReactDatePicker from 'react-datepicker';
import { Colors } from '@dm/bigfish';
import { PMTransactionTypeEnum, PropertyManagementTransactionStatus } from '@dm/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Actions from 'redux/Actions';
import MultiSelect, { TOptions } from '../components/MultiSelect';

dayjs.extend(utc);

type ComponentProps = {
    propertyGroupOptions: TOptions[];
}

const TotalTransactionFilter: FC<ComponentProps> = ({
    propertyGroupOptions,
}) => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector((state) => state.dashboard.filters);

    useEffect(() => {
        dispatch(Actions.getDashboardTransactionCountAttempt({
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
            name: filters.propertyGroupName.length > 0 ? filters.propertyGroupName.join(',') : '',
            transactionStatus: filters.transactionStatus.length > 0 ? filters.transactionStatus.join(',') : undefined,
            transactionType: filters.transactionType.length > 0 ? filters.transactionType.join(',') : undefined,
        }));
        dispatch(Actions.getDashboardTransactionListAttempt({
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
            name: filters.propertyGroupName.length > 0 ? filters.propertyGroupName.join(',') : undefined,
            transactionStatus: filters.transactionStatus.length > 0 ? filters.transactionStatus.join(',') : undefined,
            transactionType: filters.transactionType.length > 0 ? filters.transactionType.join(',') : undefined,
            index: filters.index,
        }));
    }, [filters.endDate, filters.startDate, filters.propertyGroupName, filters.transactionStatus, filters.transactionType]);

    useEffect(() => {
        if (dayjs(filters.endDate).isBefore(filters.startDate)) {
            dispatch(Actions.setFilters({ ...filters, endDate: filters.startDate }));
        }
    }, [filters.endDate, filters.startDate]);

    const transactionTypeOptions = Object.keys(PMTransactionTypeEnum)
        .filter((key) => Number.isNaN(Number(key))) // Exclude numeric keys
        .map((key) => ({
            label: key.charAt(0).toUpperCase() + key.slice(1),
            value: key.charAt(0).toUpperCase() + key.slice(1),
        }));

    const statusOptions = Object.keys(PropertyManagementTransactionStatus)
        .filter((key) => Number.isNaN(Number(key))) // Exclude numeric keys
        .map((key) => ({
            label: key.charAt(0).toUpperCase() + key.slice(1),
            value: key.charAt(0).toUpperCase() + key.slice(1),
        }));

    const handleSelectPropertyGroup = (type: string) => {
        if (filters.propertyGroupName.includes(type)) {
            dispatch(Actions.setFilters({
                ...filters, propertyGroupName: filters.propertyGroupName.filter((item) => item !== type),
            }));
        } else {
            dispatch(Actions.setFilters({
                ...filters,
                propertyGroupName: [...filters.propertyGroupName, type],
            }));
        }
    };

    const handleSelectTransactionType = (type: string) => {
        if (filters.transactionType.includes(type)) {
            dispatch(Actions.setFilters({
                ...filters,
                transactionType: filters.transactionType.filter((item) => item !== type),
            }));
        } else {
            dispatch(Actions.setFilters({
                ...filters,
                transactionType: [...filters.transactionType, type],
            }));
        }
    };

    const handleSelectStatus = (type: string) => {
        if (filters.transactionStatus.includes(type)) {
            dispatch(Actions.setFilters({
                ...filters,
                transactionStatus: filters.transactionStatus.filter((item) => item !== type),
            }));
        } else {
            dispatch(Actions.setFilters({
                ...filters,
                transactionStatus: [...filters.transactionStatus, type],
            }));
        }
    };

    const resetFilter = () => {
        dispatch(Actions.resetFilters());
    };

    return (
        <FilterContainer>
            <Filter>
                <MultiSelect
                    options={propertyGroupOptions}
                    selectedOption={filters.propertyGroupName}
                    setSelectedOption={handleSelectPropertyGroup}
                    placeholder='Property Group'
                />
                <MultiSelect
                    options={transactionTypeOptions}
                    selectedOption={filters.transactionType}
                    setSelectedOption={handleSelectTransactionType}
                    placeholder='Transaction Type'
                />
                <MultiSelect
                    options={statusOptions}
                    selectedOption={filters.transactionStatus}
                    setSelectedOption={handleSelectStatus}
                    placeholder='Status'
                />
                <div>
                    <StyledDatePicker
                        value={dayjs(filters.startDate).format('MM/YYYY')}
                        selected={filters.startDate}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onChange={(e: Date) => dispatch(Actions.setFilters({ ...filters, startDate: e }))}
                        placeholderText='Start Month'
                        showMonthYearPicker
                        showFullMonthYearPicker
                    />
                </div>
                <div>
                    <StyledDatePicker
                        value={dayjs(filters.endDate).format('MM/YYYY')}
                        selected={filters.endDate}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        onChange={(e: Date) => dispatch(Actions.setFilters({ ...filters, endDate: e }))}
                        placeholderText='End Month'
                        showMonthYearPicker
                        showFullMonthYearPicker
                    />
                </div>
            </Filter>
            <ClearFilterButton
                onClick={() => resetFilter()}
            >
                Clear Filter
            </ClearFilterButton>
        </FilterContainer>
    );
};

const FilterContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 20px;
    justify-content: space-between;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
`;
const Filter = styled.div`
    display: flex;
    gap:20px;
`;
const ClearFilterButton = styled.button`
    border: none;
    color: #fff;
    background-color: ${Colors.primary};
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 150px;
    height: 40px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    /* box-sizig: border-box; */
    border: solid 1px #a2a2a2;
    border-radius: 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    > .react-datepicker-wrapper {
        width: 150px;
    }
    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    }
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

export default TotalTransactionFilter;
