import React, { useEffect, useState } from 'react';
import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';
import { BreadCrumb, Paginator } from '@dm/bigfish';
import styled from 'styled-components';
import MainContainer from 'components/MainContainer';
import { PaginatedViewAllPMTransactionsResponse } from '@dm/types';
import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import TotalTransactionFilter from './TotalTransactionFilter';
import TransactionsLineChart from '../components/TransactionsLineChart';
import { TOptions } from '../components/MultiSelect';
import TransactionList from '../components/TransactionList';

const TotalTransactions = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const filters = useAppSelector(state => state.dashboard.filters);
    const dashboardTransactionCount = useAppSelector(state => state.dashboard.dashboardTransactionCount);
    const dashboardTransactionList = useAppSelector(state => state.dashboard.dashboardTransactionList);

    const [currentMinPages, setCurrentMinPages] = useState(0);
    const [currentMaxPages, setCurrentMaxPages] = useState(20);
    const [maxPages, setMaxPages] = useState(0);
    const [paginationData, setPaginationData] = useState<(string | number)[] | []>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);

    useEffect(() => {
        return () => {
            dispatch(Actions.resetFilters());
        };
    }, []);

    useEffect(() => {
        setMaxPages(dashboardTransactionList.maxIndex);

        if (dashboardTransactionList.maxIndex === 0) {
            setPaginationData([1]);
            setPageIndex(1);
            dispatch(Actions.setFilters({
                ...filters,
                index: 1,
            }));
            return;
        }
        setPaginationData(Array.from({ length: dashboardTransactionList.maxIndex }, (_, i) => i + 1));
    }, [dashboardTransactionList]);

    useEffect(() => {
        dispatch(Actions.setFilters({
            ...filters,
            index: pageIndex,
        }));
        dispatch(Actions.getDashboardTransactionListAttempt({
            startDate: filters.startDate.toISOString(),
            endDate: filters.endDate.toISOString(),
            name: filters.propertyGroupName.length > 0 ? filters.propertyGroupName.join(',') : undefined,
            transactionStatus: filters.transactionStatus.length > 0 ? filters.transactionStatus.join(',') : undefined,
            transactionType: filters.transactionType.length > 0 ? filters.transactionType.join(',') : undefined,
            index: filters.index,
        }));
    }, [pageIndex]);

    const breadCrumb = [
        {
            label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
            onClick: () => { NavActions.navToHome(); },
        },
        {
            label: Translate.t('BreadCrumb.BreadCrumbTotalTransactions'),
        },
    ];

    const propertyGroupFilter = ():TOptions[] => {
        if (dashboardTransactionCount.length > 0 && dashboardTransactionCount[0].propertyGroup.length > 0) {
            return dashboardTransactionCount[0].propertyGroup.map((item) => (
                {
                    label: item.name,
                    value: item.name,
                }
            ));
        }
        return [];
    };

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const nextPaginationNumber = () => {
        if (currentMaxPages < maxPages) {
            setCurrentMinPages(currentMinPages + 20);
            setCurrentMaxPages(currentMaxPages + 20);
        }

        if (pageIndex < maxPages) {
            setPageIndex(pageIndex + 1);
        }
    };

    const prevPaginationNumber = () => {
        if (currentMinPages > 0) {
            setCurrentMinPages(currentMinPages - 20);
            setCurrentMaxPages(currentMaxPages - 20);
        }

        if (pageIndex > 1) {
            setPageIndex(pageIndex - 1);
        }
    };

    const firstPaginationNumber = () => {
        setCurrentMinPages(0);
        setCurrentMaxPages(20);

        setPageIndex(1);
    };

    const lastPaginationNumber = () => {
        setCurrentMinPages(maxPages - 20);
        setCurrentMaxPages(maxPages);

        setPageIndex(maxPages);
    };

    const onClickPaginationNumber = (index: number) => {
        const min = index - 1;
        const max = index + 1;

        if (min < 0) {
            setCurrentMinPages(0);
            setCurrentMaxPages(20);
        } else if (max > maxPages) {
            setCurrentMinPages(maxPages - 20);
            setCurrentMaxPages(maxPages);
        } else {
            setCurrentMinPages(min);
            setCurrentMaxPages(max);
        }

        setPageIndex(index);
    };

    return (
        <>
            <BreadCrumb onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Content>
                    <TotalTransactionFilter propertyGroupOptions={propertyGroupFilter()} />
                    <ChartContainer>
                        <TransactionsLineChart
                            data={dashboardTransactionCount}
                        />
                    </ChartContainer>
                    <PropertyListContainer>
                        <h1>Transactions</h1>
                        <TransactionList transactionList={dashboardTransactionList} />
                        <PaginatorContainer>
                            <Paginator
                                currentIndex={pageIndex}
                                data={paginationData}
                                onNext={nextPaginationNumber}
                                onPrev={prevPaginationNumber}
                                onFirst={firstPaginationNumber}
                                onLast={lastPaginationNumber}
                                onClickIndex={onClickPaginationNumber}
                            />
                        </PaginatorContainer>
                    </PropertyListContainer>
                </Content>
            </MainContainer>
        </>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
`;
const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PropertyListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PaginatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default TotalTransactions;
