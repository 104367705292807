import { push, goBack, replace } from 'redux-first-history';

import { store } from 'redux/store';

interface INavActionState {
    [key: string]: any;
}

const navPush = (routeName: string, state?: INavActionState): void => {
    store.dispatch(push({ pathname: routeName }, state));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navReplace = (routeName: string) => {
    store.dispatch(replace({ pathname: routeName }));
};

const navReset = (stackName: string) => {
    store.dispatch(replace(stackName));
};

const navRedirectNewTab = (routeName: string): void => {
    const newTab = window.open(routeName, '_blank');

    if (newTab) {
        newTab.focus();
    }
};

const navToHome = (): void => navPush('/');
const navResetToHome = (): void => navReset('/');

const navToLogin = (): void => navPush('/login');
const navToRequestResetPassword = (): void => navPush('/requestResetPassword');

const navToDashboard = (): void => navPush('/dashboard');
const navToTotalPropertyCount = (): void => navPush('/dashboard/totalPropertyCount');
const navToTotalSubscriptionCount = (): void => navPush('/dashboard/totalSubscriptionCount');
const navToTotalTransactions = (): void => navPush('/dashboard/totalTransactions');
const navToModules = (): void => navPush('/modules');
const navToNewOwner = (): void => navPush('/newOwner');
const navToReportsOwner = (): void => navPush('/reports/owners');
const navToOwnerDetails = (id: string): void => navPush(`/reports/owners/ownerDetails/${id}`);
const navToCreateEvent = (propertyId: string, ownerId: string): void => navPush(`/reports/properties/createEvent/${propertyId}`, { ownerId });

const navToListings = (): void => navPush('/listings');
const navToNotifications = (): void => navPush('/notifications');
const navToApprovals = (): void => navPush('/approvals');
const navToWithdrawals = (): void => navPush('/reports/withdrawals');

const navToReports = (): void => navPush('/reports');
const navToPropertyReports = (): void => navPush('/reports/properties');
const navToPropertyDetails = (propertyId: string, newTab?: boolean): void => (!newTab ? navPush(`/propertyDetails/${propertyId}`) : navRedirectNewTab(`/propertyDetails/${propertyId}`));
const navToEditProperty = (newPropertyId: string): void => navPush('/editProperty', { newPropertyId });
const navToPropertyGroupDetails = (propertyGroupId: string): void => navPush(`/propertyGroupDetails/${propertyGroupId}`);
const navToEditPropertyGroup = (propertyGroupId: string): void => navPush('/editPropertyGroup', { propertyGroupId });

const navToPackagesLeaseAndSale = (propertyId: string): void => navPush('/packagesLeaseAndSale', { propertyId });
const navToLeaseAndSaleViewDocuments = (propertyIndividualId: string): void => navPush('/leaseAndSaleViewDocument', { propertyIndividualId });
const navToPackagesVacantPosession = (propertyId: string): void => navPush('/packagesVacantPosession', { propertyId });
const navToPackagesDefectInspection = (propertyId: string): void => navPush('/packagesDefectInspection', { propertyId });
const navToVacantPosessionViewDocuments = (propertyIndividualId: string): void => navPush('/vacantPosessionViewDocument', { propertyIndividualId });
const navToDefectInspectionViewDocuments = (propertyIndividualId: string): void => navPush('/defectInspectionViewDocument', { propertyIndividualId });
const navToPackagesVacantManagement = (propertyId: string): void => navPush('/packagesVacantManagement', { propertyId });
const navToVacantManagementViewDocuments = (propertyIndividualId: string): void => navPush('/VacantManagementViewDocument', { propertyIndividualId });
const navToPackagesPropertyManagement = (propertyId: string): void => navPush('/packagesPropertyManagement', { propertyId });
const navToPropertyManagementViewDocuments = (propertyIndividualId: string): void => navPush('/propertyManagementViewDocument', { propertyIndividualId });
const navToPropertyManagementViewTransaction = (propertyIndividualId: string): void => navPush('/propertyManagementViewTransaction', { propertyIndividualId });

const navToPayments = (): void => navPush('/reports/payments');
const navToCobrokesReport = (): void => navPush('/reports/cobrokes');
const navToTransactionsReport = (): void => navPush('/reports/transactions');

const navToReaReport = (): void => navPush('/reports/rea');
const navToReaDetails = (reaId: string): void => navPush(`/reports/rea/reaDetail/${reaId}`);

const navToReportSubAdmins = (): void => navPush('/reports/sub-admins');

export default {
    navBack,

    navResetToHome,
    navToHome,

    navToLogin,
    navToRequestResetPassword,

    navToDashboard,
    navToTotalPropertyCount,
    navToTotalSubscriptionCount,
    navToTotalTransactions,
    navToModules,
    navToNewOwner,
    navToReportsOwner,
    navToOwnerDetails,
    navToCreateEvent,

    navToListings,
    navToNotifications,
    navToReports,
    navToApprovals,
    navToWithdrawals,

    navToPropertyReports,
    navToPropertyDetails,
    navToEditProperty,
    navToPropertyGroupDetails,
    navToEditPropertyGroup,

    navToPackagesLeaseAndSale,
    navToLeaseAndSaleViewDocuments,
    navToPackagesVacantPosession,
    navToPackagesDefectInspection,
    navToVacantPosessionViewDocuments,
    navToDefectInspectionViewDocuments,
    navToPackagesVacantManagement,
    navToVacantManagementViewDocuments,
    navToPackagesPropertyManagement,
    navToPropertyManagementViewDocuments,
    navToPropertyManagementViewTransaction,

    navToPayments,
    navToCobrokesReport,
    navToTransactionsReport,
    navToReaReport,

    navToReaDetails,

    navToReportSubAdmins,
};
