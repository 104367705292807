import { Button, Colors } from '@dm/bigfish';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

const AllDashboardFilter = (): JSX.Element => {
    const [selectedYear, setSelectedYear] = useState<string>();

    const resetFilter = () => {
        setSelectedYear(undefined);
    };
    return (
        <FilterContainer>
            <div>
                <StyledDatePicker
                    selected={selectedYear ? new Date(selectedYear) : undefined}
                    showYearPicker
                    onChange={(date: Date) => setSelectedYear(date.getFullYear().toString())}
                    maxDate={new Date()}
                    dateFormat='yyyy'
                    placeholderText='Year'
                    wrapperClassName=''
                />
            </div>
            <ClearFilterButton
                onClick={() => resetFilter()}
            >
                Clear Filter
            </ClearFilterButton>
        </FilterContainer>
    );
};
const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
const ClearFilterButton = styled.button`
    border: none;
    color: #fff;
    background-color: ${Colors.primary};
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
`;
const StyledDatePicker = styled(ReactDatePicker)`
    height: 40px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    box-sizing: border-box;
    border: solid 1px #a2a2a2;
    border-radius: 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    }
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

export default AllDashboardFilter;
