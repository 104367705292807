import { Colors } from '@dm/bigfish';
import { PaginatedViewAllPMTransactionsResponse, PMTransactionTypeEnum, PropertyManagementTransactionStatus } from '@dm/types';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

type TransactionListProps = {
    transactionList: PaginatedViewAllPMTransactionsResponse;
};

const TransactionList: FC<TransactionListProps> = ({
    transactionList,
}) => {
    const getDashboardTransactionListAttempt = useAppSelector(state => state.dashboard.actions.getDashboardTransactionList);
    const getDashboardTransactionListFailure = useAppSelector(state => state.dashboard.error.getDashboardTransactionList);

    const tableHeader = ['Property Name', 'Unit No', 'Description', 'Date Created', 'type', 'status'];

    const renderTableHeader = () => tableHeader.map((item) => <th>{item}</th>);

    const renderTableContent = () => {
        if (!transactionList.data.length) return <tr><td colSpan={6} style={{ textAlign: 'center' }}>No data</td></tr>;

        if (getDashboardTransactionListAttempt) {
            return (
                <tr>
                    <td colSpan={6}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Oval
                                height={200}
                                width={200}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        // if (getDashboardTransactionListFailure) {
        //     return (
        //         <tr>
        //             <td colSpan={3} style={{ textAlign: 'center' }}>
        //                 {getDashboardTransactionListFailure}
        //             </td>
        //         </tr>
        //     );
        // }

        return transactionList.data.map((item) => {
            return (
                <tr key={item.propertyId}>
                    <td>
                        {item.propertyName}
                    </td>
                    <td>
                        {item.unitNo}
                    </td>
                    <td>
                        {item.description}
                    </td>
                    <td>
                        {dayjs(item.createdAt).format('DD/MM/YYYY')}
                    </td>
                    <td>
                        {PMTransactionTypeEnum[item.transactionType]}
                    </td>
                    <td>
                        {PropertyManagementTransactionStatus[item.status]}
                    </td>
                </tr>
            );
        });
    };
    return (
        <PropertyTable>
            <thead>{renderTableHeader()}</thead>
            <tbody>{renderTableContent()}</tbody>
        </PropertyTable>
    );
};

const PropertyTable = styled.table`
    border: 1px solid #D3D3D3;
    border-collapse: collapse;

    th,td {
        padding: 20px;
        border: 1px solid #D3D3D3;
        text-align: center;
    }
`;

export default TransactionList;
